<template>
    <div class="listing-seo-text">
        <div
            :class="{ expanded: isDescriptionExpanded }"
            class="seo-description"
            v-html="seoTextContent"
        />
        <ButtonComponent
            v-if="!isDescriptionExpanded"
            :variant="BUTTON_VARIANT"
            class="show-more-button"
            @click="expandDescription()"
        >
            <template #icon>
                <Icon :icon="Add" width="20px" height="20px" />
            </template>
            {{ $t('Show more') }}
        </ButtonComponent>
    </div>
</template>

<script>
import { Add } from '@eobuwie-ui/icons/v1/other';

import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@eobuwie-ui/components/ButtonComponent/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

export default {
    name: 'ListingSeoText',

    components: {
        Icon,
        ButtonComponent,
    },

    props: {
        seoTextContent: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isDescriptionExpanded: false,
        };
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY_ACCENT;
        this.Add = Add;
    },

    methods: {
        expandDescription() {
            this.isDescriptionExpanded = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.listing-seo-text {
    @apply pt-5 w-full mx-auto;

    .show-more-button {
        @apply mt-4;
    }

    .seo-description {
        @apply line-clamp-5;

        &.expanded {
            @apply overflow-visible block line-clamp-none;
        }

        &:deep() {
            .seo-main-heading {
                @apply text-m leading-m font-semibold mb-4;
            }

            .seo-paragraph {
                @apply text-s leading-s;
            }

            .seo-strong {
                @apply font-semibold;
            }

            .seo-link {
                @apply underline;
            }

            .seo-list {
                @apply mt-3 list-disc ml-5;
            }

            .seo-bullet {
                &::marker {
                    @apply text-gray3;
                }
            }

            .seo-second-heading {
                @apply text-r leading-r mt-4 mb-12p;
            }

            .seo-faq-container {
                @apply bg-gray8 py-3 px-4;

                &:not(:first-child) {
                    @apply mt-3;
                }
            }

            .seo-faq-question {
                @apply text-s leading-s font-semibold;
            }
        }
    }

    @screen lg {
        .show-more-button {
            @apply hidden;
        }

        .seo-description {
            @apply overflow-visible block line-clamp-none;

            &:deep() {
                .seo-main-heading {
                    @apply text-l leading-l mb-5;
                }

                .seo-paragraph {
                    @apply text-r leading-r;
                }

                .seo-second-heading {
                    @apply text-m leading-m mt-5 mb-3;
                }

                .seo-faq-question {
                    @apply text-r leading-r;
                }
            }
        }
    }

    @media (min-width: 1440px) {
        @apply w-3/4;
    }
}
</style>
